<template>
  <b-modal
    id="modal-notes-info"
    v-model="control"
    modal-class="modal-primary"
    title="Zero Payment"
    title-tag="h3"
    centered
    hide-footer
    @hidden="close"
  >
    <b-container>
      <program-client-header
        :client="client"
        :program="program"
      />
      <b-row>
        <b-col :cols="6">
          <b-form-group label="Amount:">
            <b-form-input
              v-model="zeroData.amount"
              :disabled="inputInactive"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="6">
          <b-form-group label="Date:">
            <b-form-datepicker
              v-model="zeroData.date"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              :disabled="inputInactive"
              placeholder="mm/dd/yyyy"
            />
          </b-form-group>

        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Comment:">
            <b-form-textarea
              v-model="zeroData.comment"
              :disabled="inputInactive"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Request by:">
            <b-form-input
              v-model="zeroData.requestBy"
              class="text-center"
              :disabled="inputInactive"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>
<script>
import ProgramClientHeader from '@/views/crm/views/sales-made/components/modals/ProgramClientHeader.vue'
import ZeroPaymentService from '../../../../../../commons/components/zero-payment/services/zero-payment.service'

export default {
  components: { ProgramClientHeader },
  props: {
    transaction_id: { required: true, type: String },
    client: { required: true, type: String },
    program: { required: true, type: String },
  },
  data() {
    return {
      inputInactive: true,
      AllData: {},
      control: false,
    }
  },
  computed: {
    zeroData() {
      return {
        amount: '0.00',
        date: this.AllData.settlement_date,
        comment: this.AllData.comment,
        requestBy: `${this.AllData.user_name} | ${this.AllData.created_at}`,
      }
    },
  },
  async created() {
    await this.chargeData()
    this.control = true
  },
  methods: {
    close() {
      this.$emit('close')
      this.control = false
    },
    async chargeData() {
      const data = await ZeroPaymentService.getNotesZeroPayment(this.transaction_id)
      this.AllData = data.data[0]
    },
  },
}
</script>
