<template>
  <b-modal
    id="modal-action"
    modal-class="modal-primary"
    :title="actionText"
    title-tag="h3"
    :header-bg-variant="actionClass"
    @hidden="close"
    centered
  >
    <template #default>
      <b-container>
        <b-row>
          <b-col>
            <b-form-group label="Program:">
              <b-form-input
                class="rounded text-primary border-primary font-medium-1 text-center py-1s"
                v-model="program"
                :disabled="inputInactive"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Client:">
              <b-form-input
                class="rounded text-primary border-primary font-medium-1 text-center py-1s"
                v-model="client"
                :disabled="inputInactive"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Observation:">
              <b-form-textarea v-model="observation" />
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </template>
    <template #modal-footer>
      <b-button :variant="actionClass" @click="sendData">
        {{ actionText }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import zeroPaymentService from '../../../../../../commons/components/zero-payment/services/zero-payment.service'
import { mapMutations, mapGetters } from "vuex";
import ZeroPaymentService from "@/views/bookeeping/views/zero-payment/service/zero-payment.service";
export default {
  props: {
    action: { required: true, type: Number },
    user: { required: true, type: Number },
    transaction: { required: true, type: String },
    program: { required: true, type: String },
    client: { required: true, type: String },
    requestedBy: { required: true, type: Number },
    clientAccount: { required: true, type: String },
  },
  data() {
    return {
      inputInactive: true,
      observation: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async sendData() {
      let parameters;
      if (this.action == 3) {
        parameters = {
          idzero: this.transaction,
          status: this.action,
          date_approve: 2,
          id_user: this.user,
          com: this.observation,
          requested_by: this.requestedBy,
          client_name: this.client,
          account: this.clientAccount
        };
      } else {
        parameters = {
          idzero: this.transaction,
          status: this.action,
          date_approve: 1,
          id_user: this.user,
          com: this.observation,
          requested_by: this.requestedBy,
          client_name: this.client,
          account: this.clientAccount
        };
      }
      const confirmed = await this.showConfirmSwal();
      if (confirmed.isConfirmed) {
        try {
          const data = await zeroPaymentService.updateNoteZeroPayment(
            parameters
          );
          if (data.status === 200) {
            this.showSuccessSwal();
            this.$emit("refresh");
            // await this.refreshCountPending(8, "zero-payment-bookeeping", this.getStatusId());
            // await this.refreshCountPending(6, "zero-payment-court-info", this.getStatusId());
            this.close();
          }
        } catch (error) {
          this.showErrorSwal(error);
        }
      }
    },
    ...mapMutations({
      countPending: "SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY",
    }),

    async refreshCountPending(modulo, routeName, status) {
      const params = {
        id_global: this.currentUser.user_id,
        id_status: status,
        id_modul: modulo, //Bookeeping
        id_role: this.currentUser.role_id,
      };

      const { data } = await ZeroPaymentService.getZeroPaymentCounter(params);
      if (data.length >= 0) {
        const payload = {
          routeName: routeName,
          tag: data.length,
        };
        //   store.commit("SidebarStore/UPDATE_SIDEBAR_ITEM_PROPERTY", payload);
        await this.countPending(payload);
      }
    },

    getStatusId() {
      let role_id = this.currentUser.role_id;
      if (role_id == 1) {
        return 3;
      } else if (role_id == 2) {
        return 2;
      }
    },
  },
  computed: {
    actionText() {
      switch (parseInt(this.action)) {
        case 1:
          return "Approve";
          break;
        case 2:
          return "Disapprove";
          break;
        case 3:
          return "Send";
          break;
      }
    },
    actionClass() {
      switch (parseInt(this.action)) {
        case 1:
          return "primary";
          break;
        case 2:
          return "danger";
          break;
        case 3:
          return "success";
          break;
      }
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    this.refreshCountPending();
  },
};
</script>
