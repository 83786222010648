<template>
  <b-container id="fluid" fluid>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reload="$refs['zeroPaymentList'].refresh()"
      @reset-all-filters="refreshTable"
    >
      <template #table>
        <b-table
          ref="zeroPaymentList"
          :items="myProvider"
          :fields="fields"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          show-empty
          sticky-header="500px"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(client_name)="data">
            <view-client-details
              :client-information="{
                ...data.item,
                client_account_id: data.item.client_acount_id,
              }"
            />
          </template>
          <template #cell(advisor_name)="data">
            {{ data.item.advisor_name ? data.item.advisor_name : "-" }}
          </template>
          <template #cell(account)="data">
            <b-row>
              <b-col>
                {{ data.item.account }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <status-account
                  :account="data.item"
                  :text="true"
                ></status-account>
              </b-col>
            </b-row>
          </template>
          <template #cell(note_status)="data">
            <div class="d-flex justify-content-around">
              <b-badge
                class="text-center w-100"
                :class="colorStatusNote(data.item.note_status)"
                pill
              >
                {{
                  data.item.note_status == 3
                    ? data.item.note_status + currentUser.role_id
                    : data.item.note_status | nameStatusNote
                }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="data">
            <center>
              <div
                v-if="
                  (currentUser.role_id == 1 || currentUser.role_id == 2) &&
                  data.item.note_status == 3
                "
                class="d-flex justify-content-around"
              >
                <b-button
                  v-b-modal.modal-action
                  v-b-tooltip.hover.top="'Approve'"
                  class="button d-flex flex-row justify-content-center align-items-center"
                  variant="primary"
                  @click="activeActionModal(data.item, 1)"
                >
                  <feather-icon icon="ThumbsUpIcon" size="15" />
                </b-button>
                <b-button
                  v-b-modal.modal-action
                  v-b-tooltip.hover.top="'Disapprove'"
                  class="button d-flex flex-row justify-content-center align-items-center"
                  variant="danger"
                  @click="activeActionModal(data.item, 2)"
                >
                  <feather-icon icon="ThumbsDownIcon" size="15" />
                </b-button>
              </div>
              <!-- <div
                v-if="currentUser.role_id == 2 && data.item.note_status == 2"
              >
                <center>
                  <b-button
                    v-b-modal.modal-action
                    v-b-tooltip.hover.top="'Send'"
                    class="button"
                    variant="success"
                    @click="activeActionModal(data.item, 3)"
                  >
                    <feather-icon icon="SendIcon" size="15" />
                  </b-button>
                </center>
              </div> -->
            </center>
          </template>
          <template #cell(info)="data">
            <center>
              <feather-icon
                icon="InfoIcon"
                size="20"
                class="text-primary cursor-pointer"
                @click="activeNotesModal(data.item)"
              />
            </center>
          </template>
          <template #cell(tracking)="data" class="text-center">
            <center>
              <feather-icon
                icon="ListIcon"
                size="20"
                class="text-primary cursor-pointer"
                @click="activeTrackingModal(data.item)"
              />
            </center>
          </template>
          <template #cell(user_name)="data">
            <b-row
              ><b-col>{{ data.item.user_name }}</b-col></b-row
            >
            <b-row
              ><b-col>{{ data.item.created_at | myGlobal }}</b-col></b-row
            >
          </template>
        </b-table>
      </template>
    </filter-slot>
  </b-container>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import ZeroPaymentService from "../zero-payment-ce-digital.service";
import ViewClientDetails from "@/views/ce-digital/sub-modules/financial/components/ViewClientDetails.vue";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
export default {
  components: { ViewClientDetails, StatusAccount },
  data() {
    return {
      isBusy: false,
      items: "",
      startPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 25,
      },
      fields: [
        { key: "client_name", label: "Name", thClass: "text-left" },
        { key: "account", label: "Account", thClass: "text-left" },
        { key: "client_type_name", label: "Client Type", thClass: "text-left" },
        // { key: "advisor_name", label: "Advisor", thClass: "text-left" },
        { key: "note_status", label: "Status", thClass: "text-center" },
        { key: "actions", label: "Actions", thClass: "text-center" },
        { key: "info", label: "Info", thClass: "text-center" },
        { key: "tracking", label: "Tracking", thClass: "text-center" },
        { key: "user_name", label: "Requested by" },
      ],
      nextPage: "",
      endPage: "",
      totalRows: 0,
      toPage: 0,
      noteStatus: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      filters: [
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "datepicker",
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
          cols: 6,
        },
        {
          type: "select",
          label: "Status",
          margin: true,
          showLabel: true,
          options: [
            { value: 0, label: "ALL" },
            { value: 1, label: "APPROVED" },
            { value: 2, label: "DISAPPROVED" },
            { value: 3, label: "PENDING" },
          ],
          model: "",
          reduce: "value",
          selectText: "label",
          cols: 12,
          md: 2,
          visible: true,
        },
      ],
      sortBy: "created_at",
      sortDesc: true,
    };
  },
  methods: {
    ...mapActions({
      getDataTableAction: "DebtSolutionZeroPayment/getDataTableAction",
      getStatusAccountAction: "DebtSolutionZeroPayment/getStatusAccountAction",
    }),
    activeNotesModal(data) {
      this.$emit("changeStatusNotesModal", data);
      this.$bvModal.show("modal-notes-info");
    },
    activeTrackingModal(data) {
      this.$emit("changeStatusTrackingModal", data);
    },
    activeActionModal(data, action) {
      data.user_id = this.currentUser.user_id;
      data.action = action;
      data.tabla = this.$refs.zeroPaymentList;
      this.$emit("changeStatusActionModal", data);
    },
    colorStatusNote(status) {
      let selected;
      switch (status) {
        case 1:
          selected = ["bg-light-success"];
          break;
        case 2:
          selected = ["bg-light-danger"];
          break;
        case 3:
          selected = ["bg-light-warning"];
          break;
      }
      return selected;
    },

    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
    async myProvider(ctx) {
      const params = {
        text: this.filterPrincipal.model,
        from: this.filters[0].model,
        to: this.filters[1].model,
        perPage: this.paginate.perPage,
        status: this.filters[2].model,
        page: ctx.currentPage,
      };
      try {
        const data = await ZeroPaymentService.getCeDigitalZeroPayment(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
      } catch (error) {
        console.log(error);
      }

      return this.items || [];
    },
    refreshTable() {
      this.$refs.zeroPaymentList.refresh();
    },
  },
  computed: {
    nameStatus() {
      return this.noteStatus;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    if (this.currentUser.role_id !== 1) {
      // this.fields[this.fields.length - 1].label = "Creation Date";
      //   this.fields.splice(2, 1);
      this.filters[2].options[3].label = "UNDERVIEW";
    }
    await this.getStatusAccountAction();
  },
  mounted() {},
};
</script>

<style scoped>
#span_status_notes {
  padding: 3px 10px;
  border-radius: 15px;
  width: 115px;
}
#fluid {
  padding: 0;
}
.green {
  background: var(--success);
  color: #f2f2f2;
}
.red {
  background: var(--danger);
  color: #f2f2f2;
}
.yellow {
  background: var(--warning);
  color: #f2f2f2;
}
.button {
  padding: 0;
  margin: 5px 2.5px;
  height: 30px;
  width: 30px;
  border-radius: 20px;
  text-align: center;
  margin-left: 5px;
  margin-right: 5px;
}
td {
  text-align: center;
}
</style>
