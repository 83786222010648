<template>
  <b-container id="fluid" fluid>
    <header-slot></header-slot>
    <table-zero-payment
      @changeStatusNotesModal="changeStatusNotesModal"
      @changeStatusTrackingModal="changeStatusTrackingModal"
      @changeStatusActionModal="changeStatusActionModal"
    />

    <modal-zero-payment-info
      v-if="activeNotesModal"
      :transaction_id="id"
      :client="client"
      :program="'CE Digital'"
      @close="closeNotesModal"
    />
    <modal-zero-payment-tracking
      v-if="activeTrackingModal"
      :transaction_id="id"
      :client="client"
      :program="'CE Digital'"
      @close="closeTrackingModal"
    />
    <modal-zero-payment-action
      v-if="activeActionModal"
      :transaction="id"
      :action="action"
      :user="user"
      :client="client"
      :program="'CE Digital'"
      :requested-by="requestedBy"
      :client-account="clientAccount"
      @close="closeActionModal"
      @refresh="refreshTable"
    />
  </b-container>
</template>
<script>
import ModalZeroPaymentInfo from './components/ModalZeroPaymentInfo.vue'
import ModalZeroPaymentTracking from './components/ModalZeroPaymentTracking.vue'
import ModalZeroPaymentAction from './components/ModalZeroPaymentAction.vue'
import TableZeroPayment from "./components/TableZeroPayment.vue";

export default {
  components: {
    ModalZeroPaymentInfo,
    ModalZeroPaymentTracking,
    ModalZeroPaymentAction,
    TableZeroPayment,
  },
  data() {
    return {
      activeNotesModal: false,
      activeTrackingModal: false,
      activeActionModal: false,
      id: 0,
      client: "",
      program: "",
      user: 0,
      action: 0,
      tabla: null,
      requestedBy: null,
      clientAccount: null
    };
  },
  methods: {
    changeStatusNotesModal(data) {
      this.id = data.id;
      this.client = data.client_name;
      this.program = data.program;
      this.activeNotesModal = true;
    },
    closeNotesModal() {
      this.activeNotesModal = false;
    },
    changeStatusTrackingModal(data) {
      this.id = data.id;
      this.client = data.client_name;
      this.program = data.program;
      this.activeTrackingModal = true;
    },
    closeTrackingModal() {
      this.activeTrackingModal = false;
    },
    changeStatusActionModal(data) {
      this.id = data.id;
      this.client = data.client_name;
      this.program = data.program;
      this.user = data.user_id;
      this.action = data.action;
      this.tabla = data.tabla;
      this.requestedBy = data.requested_by_id;
      this.clientAccount = data.account;
      this.activeActionModal = true;
    },
    closeActionModal() {
      this.activeActionModal = false;
    },
    refreshTable() {
      this.tabla.refresh();
    },
  },
};
</script>
<style scoped>
#fluid {
  padding: 0;
}
</style>
